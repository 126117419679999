var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "general-overview applied-details", attrs: { fluid: "" } },
    [
      !_setup.errored
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "app-welcome-message" }, [
                    !_setup.readonly
                      ? _c("h1", [_vm._v("Maatregel bewerken")])
                      : _c("h1", [_vm._v("Maatregel")]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("p", { staticClass: "text-h4" }, [
                      _vm._v(_vm._s(_setup.applied.address)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("label", [_vm._v("Categorie")]),
                    _setup.applied?.definition?.category?.type?.descr
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              _setup.applied.definition.category.type.descr
                            ) +
                              " (" +
                              _vm._s(
                                _setup.applied.definition.category.mainType
                                  .descr
                              ) +
                              ")"
                          ),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ]),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("label", [_vm._v("Code")]),
                    _c("p", [_vm._v(_vm._s(_setup.applied.definition.code))]),
                  ]),
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("label", [_vm._v("Soort")]),
                    _c("p", [
                      _vm._v(_vm._s(_setup.applied.definition.kind.descr)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "5" } }, [
                    _c("label", [_vm._v("Naam")]),
                    _c("p", [_vm._v(_vm._s(_setup.applied.definition.name))]),
                  ]),
                  _c("v-col", { attrs: { cols: "7" } }, [
                    _c("label", [_vm._v("Omschrijving")]),
                    _c("p", [
                      _vm._v(_vm._s(_setup.applied.definition.description)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "5" } }, [
                    _c("label", [_vm._v("Uitgangssituatie")]),
                    _setup.applied.definition.baseline
                      ? _c("p", [
                          _vm._v(_vm._s(_setup.applied.definition.baseline)),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ]),
                  _c("v-col", { attrs: { cols: "7" } }, [
                    _c("label", [_vm._v("Uitvoerbaar op zelfstandig moment")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _setup.applied.definition
                            .isApplicableAtIndependentMoment === true
                            ? "Ja"
                            : "Nee"
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("label", [
                      _vm._v("Doelmatig beheer en onderhoud (DBO)"),
                    ]),
                    _setup.applied.definition.specialCircumstances
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              _setup.applied.definition.specialCircumstances
                            )
                          ),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "overview-wrapper" },
                [
                  _c("v-col", [
                    _c(
                      "form",
                      {
                        staticClass: "inner",
                        attrs: { id: "update-applied-form" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _setup.onUpdate.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _setup.applied.definition.alternativeName
                          ? _c(
                              "div",
                              { staticClass: "variant form-group" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "ma-0 pa-0",
                                          attrs: {
                                            light: "",
                                            "hide-details": "",
                                            label:
                                              "Alternatieve maatregel gebruikt",
                                          },
                                          model: {
                                            value:
                                              _setup.applied.useAlternative,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.applied,
                                                "useAlternative",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "applied.useAlternative",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "5" } }, [
                                      _c("label", [_vm._v("Naam")]),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _setup.applied.definition
                                              .alternativeName
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("v-col", { attrs: { cols: "7" } }, [
                                      _c("label", [_vm._v("Omschrijving")]),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _setup.applied.definition
                                              .alternativeDescription
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("label", [_vm._v("Status")]),
                                _c("v-select", {
                                  staticClass: "pt-1",
                                  attrs: {
                                    items: _setup.statusses,
                                    "item-text": "descr",
                                    "item-value": "key",
                                    label: "Status",
                                    "return-object": "",
                                    disabled: _setup.readonly,
                                    "single-line": "",
                                    "hide-details": "",
                                    required: "",
                                    outlined: "",
                                    rules: [(v) => !!v || "Selecteer status"],
                                  },
                                  model: {
                                    value: _setup.applied.status,
                                    callback: function ($$v) {
                                      _vm.$set(_setup.applied, "status", $$v)
                                    },
                                    expression: "applied.status",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _setup.applied.definition.kind.key != "EML"
                          ? [
                              _setup.applied.definition.kind.key == "EML2023"
                                ? [
                                    _setup.applied.definition.variants.length >
                                    0
                                      ? _c(
                                          "v-row",
                                          {
                                            staticClass: "variant form-group",
                                            class: {
                                              disabled:
                                                !_setup.doesNotMetConditions,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    "Randvoorwaarden waaraan niet voldaan wordt"
                                                  ),
                                                ]),
                                                _vm._l(
                                                  _setup.applied.definition
                                                    .variants,
                                                  function (itm, index) {
                                                    return _c(
                                                      "v-row",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: itm.type,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c("v-checkbox", {
                                                              staticClass:
                                                                "ma-0 pa-0",
                                                              attrs: {
                                                                light: "",
                                                                disabled:
                                                                  !_setup.doesNotMetConditions,
                                                                multiple: "",
                                                                "hide-details":
                                                                  "",
                                                                label: `${itm.condition} (${itm.conditionType.descr})`,
                                                                value: itm.type,
                                                              },
                                                              model: {
                                                                value:
                                                                  _setup.conditions,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _setup.conditions =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "conditions",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ]
                          : [
                              _c(
                                "v-row",
                                { staticClass: "variant form-group" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Variant van toepassing"
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn-toggle",
                                                    {
                                                      attrs: {
                                                        group: "",
                                                        disabled:
                                                          _setup.readonly,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.applied
                                                            .variantType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.applied,
                                                            "variantType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "applied.variantType",
                                                      },
                                                    },
                                                    _vm._l(
                                                      Array.from(
                                                        new Set(
                                                          _setup.applied.definition.variants.map(
                                                            (a) => a.type
                                                          )
                                                        )
                                                      ),
                                                      function (itm, index) {
                                                        return _c(
                                                          "v-btn",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: itm,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(itm) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _setup.applied.variantType != ""
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "Natuurlijk moment mogelijk"
                                                    ),
                                                  ]),
                                                  _c("v-checkbox", {
                                                    staticClass: "ma-0 pa-0",
                                                    attrs: {
                                                      disabled: "",
                                                      "hide-details": "",
                                                      label:
                                                        _setup.variant
                                                          .natCondition,
                                                    },
                                                    model: {
                                                      value:
                                                        _setup.variant
                                                          .natMoment,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _setup.variant,
                                                          "natMoment",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "variant.natMoment",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _setup.applied.variantType != ""
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "Zelfstandig moment mogelijk"
                                                    ),
                                                  ]),
                                                  _c("v-checkbox", {
                                                    staticClass: "ma-0 pa-0",
                                                    attrs: {
                                                      disabled: "",
                                                      "hide-details": "",
                                                      label:
                                                        _setup.variant
                                                          .indCondition,
                                                    },
                                                    model: {
                                                      value:
                                                        _setup.variant
                                                          .indMoment,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _setup.variant,
                                                          "indMoment",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "variant.indMoment",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Uitgangssituatie obv een referentietechniek"
                                                ),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _setup.variant.techniques
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Economische randvoorwaarde(n)"
                                                ),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _setup.variant.econPrecon
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Technische randvoorwaarde(n)"
                                                ),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _setup.variant.techPrecon
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                        _c(
                          "v-row",
                          { staticClass: "form-group" },
                          [
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("label", [_vm._v("Investering €")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _setup.applied.investmentInd,
                                    expression: "applied.investmentInd",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  step: "1",
                                  min: "0",
                                  max: "10000000000000",
                                  disabled: _setup.readonly,
                                  required:
                                    _setup.investmentAndRecoupedYearsRequired,
                                  name: "investment",
                                  placeholder: "Investering",
                                },
                                domProps: {
                                  value: _setup.applied.investmentInd,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _setup.applied,
                                      "investmentInd",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("label", [_vm._v("Terugverdientijd (jaren)")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _setup.applied.recoupedYearsInd,
                                    expression: "applied.recoupedYearsInd",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  step: "0.1",
                                  min: "-1000",
                                  max: "1000",
                                  disabled: _setup.readonly,
                                  required:
                                    _setup.investmentAndRecoupedYearsRequired,
                                  name: "recoupedYears",
                                  placeholder: "Terugverdientijd",
                                },
                                domProps: {
                                  value: _setup.applied.recoupedYearsInd,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _setup.applied,
                                      "recoupedYearsInd",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "form-group" },
                          [
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("label", [_vm._v("Voorsteldatum")]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "date",
                                  disabled: _setup.datesReadonly,
                                  name: "proposalDate",
                                },
                                domProps: {
                                  value: _setup.applied.proposalDate,
                                },
                                on: {
                                  input: function ($event) {
                                    _setup.applied.proposalDate =
                                      $event.target.value !== ""
                                        ? $event.target.value
                                        : undefined
                                  },
                                },
                              }),
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("label", [_vm._v("Datum ingepland")]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "date",
                                  disabled: _setup.datesReadonly,
                                  name: "plannedDate",
                                },
                                domProps: { value: _setup.applied.plannedDate },
                                on: {
                                  input: function ($event) {
                                    _setup.applied.plannedDate =
                                      $event.target.value !== ""
                                        ? $event.target.value
                                        : undefined
                                  },
                                },
                              }),
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("label", [_vm._v("Datum uitgevoerd")]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "date",
                                  disabled: _setup.datesReadonly,
                                  name: "achievedDate",
                                },
                                domProps: {
                                  value: _setup.applied.achievedDate,
                                },
                                on: {
                                  input: function ($event) {
                                    _setup.applied.achievedDate =
                                      $event.target.value !== ""
                                        ? $event.target.value
                                        : undefined
                                  },
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "4" } }, [
                                  _c("label", [
                                    _vm._v("Reductie elektra (kWh/jaar)"),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _setup.applied.reductionElec,
                                        expression: "applied.reductionElec",
                                      },
                                    ],
                                    ref: "reductionElec",
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "reductionElec",
                                      type: "number",
                                      step: "0.01",
                                      disabled: _setup.readonly,
                                      required:
                                        _setup.atLeastOneReductionRequired,
                                      name: "reductionElec",
                                      placeholder: "Reductie elektra",
                                    },
                                    domProps: {
                                      value: _setup.applied.reductionElec,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _setup.applied,
                                          "reductionElec",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("v-col", { attrs: { cols: "4" } }, [
                                  _c("label", [
                                    _vm._v("Reductie gas (m³/jaar)"),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _setup.applied.reductionGas,
                                        expression: "applied.reductionGas",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      disabled: _setup.readonly,
                                      required:
                                        _setup.atLeastOneReductionRequired,
                                      name: "reductionGas",
                                      placeholder: "Reductie gas",
                                    },
                                    domProps: {
                                      value: _setup.applied.reductionGas,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _setup.applied,
                                          "reductionGas",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("v-col", { attrs: { cols: "4" } }, [
                                  _c("label", [
                                    _vm._v("Reductie warmte (GJ/jaar)"),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _setup.applied.reductionHeat,
                                        expression: "applied.reductionHeat",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      disabled: _setup.readonly,
                                      required:
                                        _setup.atLeastOneReductionRequired,
                                      name: "reductionHeat",
                                      placeholder: "Reductie warmte",
                                    },
                                    domProps: {
                                      value: _setup.applied.reductionHeat,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _setup.applied,
                                          "reductionHeat",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "4" } }, [
                                  _c("label", [
                                    _vm._v("Totale reductie (GJ/jaar)"),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _setup.applied.reductionTotalGJ,
                                        expression: "applied.reductionTotalGJ",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      disabled: "",
                                      name: "reductionTotalGJ",
                                      placeholder: "Reductie totaal",
                                    },
                                    domProps: {
                                      value: _setup.applied.reductionTotalGJ,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _setup.applied,
                                          "reductionTotalGJ",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("v-col", { attrs: { cols: "4" } }, [
                                  _c("label", [
                                    _vm._v("Reductie CO₂ (kg/jaar)"),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _setup.applied.reductionKgCo2,
                                        expression: "applied.reductionKgCo2",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      disabled: "",
                                      name: "reductionKgCo2",
                                      placeholder: "Reductie CO₂ totaal",
                                    },
                                    domProps: {
                                      value: _setup.applied.reductionKgCo2,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _setup.applied,
                                          "reductionKgCo2",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                            _setup.userStore.isPartyHome
                              ? _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _c("label", [
                                        _vm._v("Warmtebehoefte (kWh/m²)"),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _setup.applied.heatingNeed,
                                            expression: "applied.heatingNeed",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled: "",
                                          name: "heatingNeed",
                                          placeholder: "Warmtebehoefte",
                                        },
                                        domProps: {
                                          value: _setup.applied.heatingNeed,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _setup.applied,
                                              "heatingNeed",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("v-col", [
                                      _c("label", [_vm._v("Energielabel Oud")]),
                                      _c(
                                        "p",
                                        [
                                          _c("energy-label", {
                                            attrs: {
                                              label:
                                                _setup.applied.energyLabelOld,
                                              estimate: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("v-col", [
                                      _c("label", [
                                        _vm._v("Energielabel Nieuw"),
                                      ]),
                                      _c(
                                        "p",
                                        [
                                          _c("energy-label", {
                                            attrs: {
                                              label:
                                                _setup.applied.energyLabelNew,
                                              estimate: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _setup.userStore.isPartyHome
                              ? _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _c("label", [
                                        _vm._v("Aantal woningen toepasbaar"),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _setup.applied
                                                .uniqueResidencesGroupsDetailCount,
                                            expression:
                                              "applied.uniqueResidencesGroupsDetailCount",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled: "",
                                          name: "uniqueResidencesGroupsDetailCount",
                                          placeholder:
                                            "Aantal woningen toepasbaar",
                                        },
                                        domProps: {
                                          value:
                                            _setup.applied
                                              .uniqueResidencesGroupsDetailCount,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _setup.applied,
                                              "uniqueResidencesGroupsDetailCount",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("label", [_vm._v("Toelichting")]),
                                _c("v-textarea", {
                                  attrs: {
                                    solo: "",
                                    name: "description",
                                    disabled: _setup.readonly,
                                    label: "",
                                  },
                                  model: {
                                    value: _setup.applied.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _setup.applied,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "applied.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _setup.applied.definition.isCertifiedMeasure
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Informatieplicht verklaring voor RVO (Let op! Dit veld wordt gebruikt in de upload naar het RVO.)"
                                      ),
                                    ]),
                                    _c("v-textarea", {
                                      attrs: {
                                        solo: "",
                                        name: "extDescription",
                                        disabled: _setup.readonly,
                                        label: "",
                                      },
                                      model: {
                                        value: _setup.applied.extDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.applied,
                                            "extDescription",
                                            $$v
                                          )
                                        },
                                        expression: "applied.extDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _setup.userStore.hasRoleUser && !_setup.readonly
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "primary",
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: {
                                          dark: "",
                                          type: "submit",
                                          title: "save",
                                          disabled: _setup.applyBusy,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { dark: "", left: "" } },
                                          [_vm._v("mdi-checkbox-marked-circle")]
                                        ),
                                        _vm._v(" Opslaan "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "primary",
                                        attrs: { dark: "", title: "cancel" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _setup.onCancel()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { dark: "", left: "" } },
                                          [_vm._v("mdi-cancel")]
                                        ),
                                        _vm._v(" Annuleren "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "pa-4" },
                                      [
                                        !_setup.isNew
                                          ? _c("applied-logging-modal", {
                                              attrs: {
                                                appliedId:
                                                  _setup.props.appliedId,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    !_setup.applied.isCertifiedMeasure &&
                                    !_setup.isNew
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "secondary",
                                            attrs: {
                                              dark: "",
                                              title: "Verwijderen",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _setup.onDelete()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "", left: "" } },
                                              [_vm._v("mdi-delete-circle")]
                                            ),
                                            _vm._v(" Verwijderen "),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "v-row",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "primary",
                                        attrs: { dark: "", title: "cancel" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _setup.onCancel()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { dark: "", left: "" } },
                                          [_vm._v("mdi-arrow-left")]
                                        ),
                                        _vm._v(" Terug "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h1", [_vm._v("U bent niet geautoriseerd voor deze pagina.")]),
              _c("h3", [_vm._v(_vm._s(_vm.error))]),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }